<template>
  <div class="move-main">
    <div id="moveBg">
      <div id="main-content">
        <div class="center">
          <div class="move-left">
            <div class="move-title">
              <img :src="obj.img1" />
              <img src="/img/qicycle.png" />
<!--              <div class="move-title-vertical"></div>-->
<!--              <div class="movt-title-text">QiCYCLE</div>-->
            </div>
            <h5 class="title-app">{{ $t("downLoad.v1") }}</h5>
            <h6 class="title2">{{ $t("downLoad.v2") }}</h6>
            <div class="download">
              <div class="sps">
                <div class="imgs">
                  <img :src="obj.img2" />
                </div>
                <div class="div11">
                  <div class="div2 div21">
                    <img class="img1" :src="obj.img3" />
                    <span class="span1">{{ $t("downLoad.v3") }}</span>
                  </div>
                </div>
                <div class="ewm1" style="display: none">
                  <div
                    class="pop-up pop-up1"
                    style="
                      z-index: 99;
                      display: flex;
                      background-color: white;
                      text-align: center;
                    "
                  >
                    <div>
                      <img
                        style="width: 111px; height: 104px"
                        :src="obj.img4"
                        alt=""
                      />
                      <div>{{ $t("downLoad.v5") }}</div>
                    </div>
                    <div class="split"></div>
                    <div>
                      <img
                        style="width: 111px; height: 104px"
                        :src="obj.img5"
                        alt=""
                      />
                      <div>{{ $t("downLoad.v6") }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sps sps2">
                <div class="imgs">
                  <img :src="obj.img6" />
                </div>
                <div class="div12">
                  <div class="div2 div22" @click="download(2)">
                    <img class="img2" :src="obj.img7" />
                    <span class="span2">{{ $t("downLoad.v4") }}</span>
                  </div>
                </div>
                <div class="ewm2" style="display: none">
                  <div
                    class="pop-up pop-up1"
                    style="
                      z-index: 99;
                      display: flex;
                      background-color: white;
                      text-align: center;
                    "
                  >
                    <div>
                      <img
                        style="width: 111px; height: 104px"
                        :src="obj.img8"
                        alt=""
                      />
                    </div>
                    <div class="split"></div>
                    <div>
                      <img
                        style="width: 111px; height: 104px"
                        :src="obj.img9"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div class="d2">
            <img :src="obj.img10" />
          </div>
        </div>
      </div>
    </div>
    <to-top />
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
export default {
  name: "download",
  data() {
    return {
      doc: "",
      obj: {
        img1: require("@/assets/image/shop-logo02-black@2x.png"),
        img2: require("@/assets/image/download/download01.png"),
        img3: require("@/assets/image/download/key01.png"),
        // img4: require("@/assets/image/download/china-app1.png"),
        img4: require("@/assets/image/download/qrcode1.png"),
        img5: require("@/assets/image/download/qrcode2.png"),
        img6: require("@/assets/image/download/download02.png"),
        img7: require("@/assets/image/download/key01.png"),
        img8: require("@/assets/image/download/china-app2.png"),
        img9: require("@/assets/image/download/china-app3.png"),
        img10: require("@/assets/image/shop-img/shop031@2x.png"),
      },
    };
  },
  components: {
    ToTop,
  },
  mounted() {
    document.addEventListener("click", (e) => {
      var className1 = ["div11", "div21", "img1", "span1"];
      var className2 = ["div12", "div22", "img2", "span2"];
      if (className1.includes(e.target.className)) {
        this.download(1);
      } else if (className2.includes(e.target.className)) {
        this.download(2);
      } else {
        this.doc.style.display = "none";
      }
    });
  },
  methods: {
    download(index) {
      var arr = [".ewm1", ".ewm2"];
      var cc = document.documentElement.clientWidth;
      arr.forEach((item) => {
        let dom = document.querySelector(item);
        if (dom.style.display == "block") {
          dom.style.display = "none";
        }
      });
      var clname =
        cc <= 980
          ? index == 1
            ? ".click-down1"
            : ".click-down2"
          : index == 1
          ? ".ewm1"
          : ".ewm2";
      var downs = document.querySelector(clname);
      var ewstyle = downs.style.display;
      downs.style.display = ewstyle == "block" ? "none" : "block";
      this.doc = downs;
    },
  },
};
</script>

<style scoped lang="stylus">
body {
  height: 100%;
}

.ewm1 {
  width: 224px;
  height: 100px;
  position: absolute;
  margin-left: 206px;
  margin-top: -10px !important;
}

.ewm2 {
  width: 224px;
  height: 100px;
  position: absolute;
  margin-left: 206px;
}

.move-main {
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;

  #moveBg {
    height: 100%;

    #main-content {
      margin: 0 10%;

      .center {
        padding-top: 10%;
        padding-left: 12%;
        display: flex;

        .move-left {
          .move-title {
            width: 326px;
            display: flex;

            >img:nth-child(1) {
              width: 34px;
              height: 28px;
              margin-top: 16px;
            }
            >img:nth-child(2) {
              width: auto;
              height: 28px;
              margin-top: 16px;
              margin-left 10px;
            }
          }

          .move-title-vertical {
            border: 1px solid rgba(51, 51, 51, 1);
            height: 30px;
            margin-left: 20px;
            margin-top: 15px;
            background-color: rgba(51, 51, 51, 1);
            color: rgba(51, 51, 51, 1);
          }

          .movt-title-text {
            font-family: 'pangmenzhengdao';
            margin-left: 20px;
            font-size: 40px;
          }

          .title-app {
            color: #000000;
            font-size: 30px;
            //letter-spacing: 6px;
            font-weight: 700;
            margin: 20px 0;
          }

          .title2 {
            color: #000000;
            //word-spacing: 15px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.1;
          }

          .sps {
            height: 70px;
            display: flex;
            cursor: pointer;
            padding-top: 18%;
            font-size: 16px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .div2 {
              padding-left: 15px;

              >img {
                padding-bottom: 4px;
              }
            }

            span {
              padding-left: 6px !important;
            }

            .imgs {
              img {
                width: 50px;
                height: 50px;
              }
            }
          }

          .sps2 {
            padding-top: 0;
            margin-top: -4px;
          }
        }

        .d2 {
          img {
            width: 400px;
            height: 466px;
            padding-left: 20px;
            padding-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .ewm1 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
  }

  .click-down1 {
    display: none;
    cursor: pointer;
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 42%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 51%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        width: 29px;
        height: 26px;
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    padding-left: 8%;
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        order: 1px solid rgba(51, 51, 51, 1);
        width: 1px;
        height: 30px;
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        color: #000000;
        font-size: 30px;
        //letter-spacing: 4px;
        font-weight: 600;
        margin: 20px 0;
      }

      .title2 {
        color: #000000;
        //letter-spacing: 8px;
        font-size: 14px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          padding-left: 4%;
          width: 250px;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
          padding-left: 10px;
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      img {
        width: 204px;
        height: 240px;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ewm1 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
  }

  .click-down1 {
    display: none;
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 42%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 51%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        width: 29px;
        height: 26px;
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    padding-left: 8%;
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        width: 1px;
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        color: #000000;
        font-size: 30px;
        //letter-spacing: 4px;
        font-weight: 600;
        margin: 20px 0;
      }

      .title2 {
        color: #000000;
        //letter-spacing: 8px;
        font-size: 14px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          padding-left: 4%;
          width: 250px;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
          padding-left: 10px;
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      img {
        width: 204px;
        height: 240px;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .ewm1 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
  }

  .click-down1 {
    display: none;
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 42%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 51%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        width: 29px;
        height: 26px;
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    padding-left: 8%;
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        width: 1px;
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        color: #000000;
        font-size: 30px;
        //letter-spacing: 4px;
        font-weight: 600;
        margin: 20px 0;
      }

      .title2 {
        color: #000000;
        //letter-spacing: 8px;
        font-size: 14px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          padding-left: 4%;
          width: 250px;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
          padding-left: 10px;
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 { // 8989
      width: 55%;
      height: 100%;

      img {
        width: 204px;
        height: 240px;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .ewm1 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
  }

  .click-down1 {
    display: none;
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 42%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 51%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        width: 29px;
        height: 26px;
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    padding-left: 8%;
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        width: 1px;
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        color: #000000;
        font-size: 30px;
        //letter-spacing: 4px;
        font-weight: 600;
        margin: 20px 0;
      }

      .title2 {
        color: #000000;
        //letter-spacing: 8px;
        font-size: 14px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          padding-left: 4%;
          width: 250px;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
          padding-left: 10px;
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      img {
        width: 204px;
        height: 240px;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .ewm1 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
    margin-top: -100px;
  }

  .ewm2 {
    height: 100px;
    position: absolute;
    margin-left: 206px;
  }

  .click-down1 {
    display: none;
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 42%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .click-down2 {
    z-index: 30;
    position: absolute;
    left: 30%;
    top: 52%;
    width: 45%;
    height: auto;
    border-radius: 6px;
    background-color: #F5F5F5;

    a {
      padding: 14px 22px;
      display: flex;
      color: #333333;

      img {
        width: 29px;
        height: 26px;
        display: block;
        padding-right: 6%;
      }

      div {
        font-size: 14px;
      }
    }

    .split {
      border-bottom: 1px solid #E3E3E3;
      margin: 0 22px;
    }
  }

  .center {
    padding-left: 8%;
    margin-top: 10%;
    height: 100%;
    display: block;

    .move-left {
      width: 100%;
      height: 100%;
      display: block;

      .move-title {
        width: 100%;
        height: 20px;
        display: flex;
        line-height: 20px;

        >img {
          width: 22px;
          height: 18px;
          margin: 0;
        }
      }

      .move-title-vertical {
        border: 1px solid rgba(51, 51, 51, 1);
        width: 1px;
        height: 18px;
        margin: 0;
        margin-left: 15px;
        background-color: rgba(51, 51, 51, 1);
        color: rgba(51, 51, 51, 1);
      }

      .movt-title-text {
        font-family: 'pangmenzhengdao';
        margin-left: 20px;
        font-size: 20px;
      }

      .title-app {
        color: #000000;
        font-size: 30px;
        //letter-spacing: 4px;
        font-weight: 600;
        margin: 20px 0;
      }

      .title2 {
        color: #000000;
        //letter-spacing: 8px;
        font-size: 14px;
      }

      .sps {
        height: 70px;
        display: flex;
        padding-top: 18%;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .div2 {
          padding-left: 4%;
          width: 250px;
          height: 40px;
          font-size: 16px;

          >img {
            padding-bottom: 4px;
          }
        }

        span {
          padding-left: 10px;
        }

        .imgs {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .sps2 {
        padding-top: 0;
        margin-top: -4px;
      }
    }

    .d2 {
      img {
        width: 204px;
        height: 240px;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .click-down1 {
    top: 46%;
  }

  .click-down2 {
    top: 58%;
  }
}
</style>
