<template>
  <div class="rightimgs">
    <div id="toTop" v-show="goTopShow" @click="toTop()">
      <img class="img" src="@/assets/image/top.png" alt="" />
    </div>
    <div id="qrCode" @mouseover="showewm()" @mouseleave="hiddenewm()">
      <img class="qrCodeIcon" src="@/assets/image/qrCode@2x.png" alt="" />
      <div v-show="qrCodeShow" class="qrCodeImg">
        <img src="@/assets/image/qiji-QR.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "toTop",
  data() {
    return {
      scrollTop: "",
      goTopShow: false,
      qrCodeShow: false,
      scrollbool: false,
      index1: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handelScroll);
  },
  computed: {
    ...mapState(["index2"]),
  },
  watch: {
    scrollTop() {
      if (this.scrollTop != 0) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    index2(index) {
      if (index != 0) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
      this.scrollbool = true;
      this.index1 = index;
    },
  },
  components: {},
  methods: {
    showewm() {
      this.qrCodeShow = true;
    },
    hiddenewm() {
      this.qrCodeShow = false;
    },
    handelScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (this.scrollTop != 0) {
        this.goTopShow = true;
      }
    },
    toTop() {
      if (this.scrollbool) {
        this.$store.state.scroll2 = this.index1;
      } else {
        let timer = null;
        var _that = this;
        cancelAnimationFrame(timer);
        timer = requestAnimationFrame(function fn() {
          if (_that.scrollTop > 0) {
            _that.scrollTop -= 50;
            document.body.scrollTop = document.documentElement.scrollTop =
              _that.scrollTop;
            timer = requestAnimationFrame(fn);
          } else {
            cancelAnimationFrame(timer);
            _that.goTopShow = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="stylus">
#toTop {
  width: 50px;
  height: 50px;
  bottom: 100px;
  right: 1.5%;
  position: fixed;
  cursor: pointer;
  z-index: 999;
  margin-right: 1%;
  display: block;

  .img {
    width: 40px;
    height: 40px;
  }
}

#qrCode {
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 1.5%;
  position: fixed;
  cursor: pointer;
  z-index: 999;
  margin-right: 1%;

  .qrCodeIcon {
    width: 40px;
    height: 40px;
  }
}

.qrCodeImg {
  position: relative;
  left: -110px;
  top: -100px;
  width: 100px;
  height: 100px;

  >img {
    width: 100%;
    height: 100%;
  }
}
</style>
